import React from 'react';
import styled from 'styled-components';
function HelpCenter() {
  return <Container>
  <Top><Left><h1>How can we help you?</h1>
  <p className='details'><a href='https://forms.gle/Xm1ZYnEftEQtGUTp8'>
<button style={{cursor:"pointer"}}>Fill out this form</button></a>
 <br/><br/>
  </p>
  
  </Left><Right><img src="/images/help_support.svg"/></Right></Top>
  
  <h3>Frequently ask questions </h3>
  <p>    Here,Some of results for you!!!</p>
  <Topthree>
     
      
      
      <Leftthree><img src='/images/phone_call_re_hx6a.svg'/>
  <p className='details'>
  <h4>is Our Calls are Safe?</h4>
  For Calls we use JistiMeet SDK which is support end-to-end encryption,<br/>For More Details Please Visit <a href='https://jitsi.org/e2ee-in-jitsi/'>Here</a>

  </p>
  
  </Leftthree><Rightthree><img src="/images/safe.svg"/>  <p className='details'>
  <h4>is Private Messages are only stay between me and receiver? </h4>
      <h4>yes,Messages that stay with you and receiver</h4>
  End-to-end encrypted messages are stored on ChatSpeedy Servers after they are delivered.



<br/><br/>
 </p></Rightthree>

 <Leftthree>
     <img src='/images/video audio small.jpg'/>
  <p className='details'>
  <h4>How to placed a Audio and Video Call?</h4>
  ChatSpeedy Offers free Video and Audio Call.To placed a Call First open ChatSpeedy App,<br/>
then click on any person name,where you want to call<br/>then chat willdow will be open,at the top section you will see Audio call icon and Video call icon,<br/>
click on any Video/Audio(which you prefer) then click Audio or Video Call Button.

  </p>
  
  </Leftthree> 
 </Topthree>
 <Topthree>
      
      
     <Leftthree>
 <p className='details'>
 <h4>How to Create a Account on ChatSpeedy?</h4>
To Create a Account on ChatSpeedy,open ChatSpeedy <br/>then click on Google Icon<img src='/images/google_icon.jpg' className='google_icon'/>,You can see list of 
Google Accounts which is Connected with your Devices.
<b>Note that,</b>Till now we are only offering sign up with Google.
 </p>
 
 </Leftthree><Rightthree><img src="/images/translate_design.jpg"/>  <p className='details'>
 <h4>How to translate any receive message in ChatSpeedy? </h4>
     <h4>Click on Receive Chat Messages which you want to translate,<br/></h4>
 then a Dialog will be open,click on "Copy and Translate" option and see the Instructions inside this dialog box.



<br/><br/>
</p></Rightthree>

<Leftthree>
 <p className='details'>
 <h4>How long my Status is Visible to Everyone?</h4>
 24hours,We will store your status only for 24 Hours<br/>
After that,It will automatically deleted from our server as well as user also.

 </p>
 
 </Leftthree><Rightthree>
     <img src="/images/chat remove at.jpg"/>
     
      <p className='details'>
 <h4>When my Private Chat Room Messages Will be Restored? </h4>
     <h4>After,3 days You will see the date and time inside your private chat Room</h4>



<br/><br/>
</p></Rightthree> 
</Topthree>
<Topthree>
<Leftthree>
     <img src='/images/block User small.jpg'/>
  <p className='details'>
  <h4>can I block any User from my list?</h4>
  Yes,you can<br/>Click on top corner,three Dot Icon you will see block/unblock user option.


  </p>
  
  </Leftthree> 
 </Topthree>
  
  </Container>;
}

export default HelpCenter;
const Footer=styled.div`
border-radius:5px 5px;

padding:10px 10px;


`
const  Toptwo=styled.div`
display:flex;
flex-direction:column;
padding:10px 10px;
margin-top:15px;
background-color:aliceblue;
@media (max-width:480px){
  flex-direction:column;
}

`
const  Topthree=styled.div`
display:flex;
padding:10px 10px;
justify-content: center;
align-items: center;
flex-direction:row;
padding:10px 10px;
margin-top:15px;
background-color:aliceblue;
@media (max-width:480px){
  flex-direction:column;
}

`
const Leftthree=styled.div`
width:330px;
margin:0px 30px 0px 30px;
img{
width:100%;
}


`
const Rightthree=styled.div`
width:330px;
margin:0px 30px 0px 30px;
img{
width:100%;
}

`
const Lefttwo=styled.div`
h1{color:black;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-weight:500;font-size:30px;color:blue;}
`
const Top=styled.div`
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
padding:10px 10px;
margin-top:15px;
background-color: rgb(228, 228, 228);
@media (max-width:480px){
  flex-direction:column;
}

`
const Left=styled.div`
h1{color:black;}
`
const Right=styled.div`
width:330px;

img{
width:100%;
}

`
 const Container=styled.main`
 padding:0 calc(3.5vw + 15px);
padding-top:10px;


padding-bottom:40px;

 ::before{    
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    }

 `
