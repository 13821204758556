import React from 'react';
import styled from 'styled-components';

function Features() {
  return <Container>
    <a href='#2'><Arrow src='/images/down-arrow.svg' /></a>
     <Top><Left><h1>Simple and Safe Messaging</h1>
     <p className='details'>

     Message your friends and family for free*. ChatSpeedy uses your phone's Internet connection to send messages so you can avoid SMS fees
    <br/><br/><p className='data-charge'>* Data charges may apply. Contact your provider for details.</p>
     </p>
     
     </Left><Right><img src="/images/main activity.jpg"/></Right></Top>

     <Top style={{background:"rgb(218, 154, 226)"}} id="2"><Left><h1>
Document Sharing Made Easy</h1>
     <p className='details'>
     Send PDFs, documents and more, without the hassle of email or file sharing apps. <br/>You can send documents up to 250 KB, so it's easy to get what you need over to who you want.




    <br/><br/>
     </p>
     
     </Left><Right><img src="/images/chat activity.jpg"/></Right></Top>
     <Top  id="2"><Left><h1>
Unlimited Free Video and Audio Calling</h1>
     <p className='details'>
     Unlimited video and audio calling with your family,friends. <br/>Connected Every time.




    <br/><br/>
     </p>
     
     </Left><Right><img src="/images/Video call activity.jpg"/></Right></Top>
     <Top style={{background:"rgb(231, 198, 157)"}} ><Left><h1>GROUP CHAT</h1>
     <p className='details'>
     

     Keep in touch with the groups of people that matter the most, like your family,coworkers or friends. With group chats, you can share messages, photos, and documents with up to unlimited<sup style={{color:"red"}}>*</sup> people at once. You can also name your group,add user and much more.


    <br/><br/>
     </p>
     
     </Left><Right><img src="/images/group messages.jpg"/></Right></Top>
     <Top style={{background:"lightgreen"}}><Left><h1>Block Unwanted User</h1>
     <p className='details'>

    with ChatSpeedy you can block any unwanted user from your messaging list, <br/>this features keep you safe,secure and fresh.
    <br/><br/>
     </p>
     
     </Left><Right><img src="/images/block user.jpg"/></Right></Top>
     <Top><Left><h1>Share your beautiful moments</h1>
     <p className='details'>

    with ChatSpeedy you can share your beautiful moments with your family,coworkers and more.<br/>Your Stories automatically delete after 24 hours of your first uploading Stories of that day when you open ChatSpeedy.
    <br/><br/>
     </p>
    
     </Left><Right><img src="/images/status activity.jpg"/></Right></Top>

</Container>;
}

export default Features;
const Arrow=styled.img`
height:40px;
margin-top:53px;
background-color:black;
width:40px;
animation:animate infinite 1.5s;
cursor:pointer;
`
const Top=styled.div`
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
padding:10px 10px;
margin-top:15px;
@media (max-width:480px){
  flex-direction:column;
}

`
const Left=styled.div`

`
const Right=styled.div`


img{
  width:330px;
}

`
 const Container=styled.main`
 padding:0 calc(3.5vw + 15px);
padding-top:10px;


padding-bottom:40px;

 ::before{    
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    }

 `