import React from 'react';
import styled from 'styled-components';

export default function Download() {
  return <Container><Nav>
      <Wrap><img src='/images/chatspeedy.png'/></Wrap>

<span className='title'>ChatSpeedy</span>
<p>Available Only On Android</p>
<Down><a href="https://play.google.com/store/apps/details?id=com.chatspeedy.chatspeedy" target={"_blank"}><button style={{cursor:"pointer"}}>Download Now</button></a></Down></Nav>
<Nav>
      <Wrap><img src='/images/ic_launcher-save-tree-playstore.png'/></Wrap>

<span className='title'>Save Tree</span>
<p>Available Only On Android</p>
<Down><a href="https://play.google.com/store/apps/details?id=com.chatspeedy.savetree" target={"_blank"}><button style={{cursor:"pointer"}}>Download Now</button></a></Down></Nav>

  </Container>;
}

const Container=styled.div`

display:flex;
align-items:center;
flex-direction:row;
justify-content:center;
justify-items:center;
background-position:center;
width:100%;
background-size: contain;
border-radius:10px 10px;
margin-top:25px;

@media (max-width:600px){
  flex-direction:column;
}
margin-top:25px;

align-items:center;
display:flex;
justify-content:center;
justify-items:center;
`
const Nav=styled.div`
width:250px;
:nth-child(1) {
  background: linear-gradient(120deg,rgb(234, 129, 221),rgb(224, 224, 209));
}
:nth-child(2) {
  background-color:lightgrey;
}
background-position:center;

background-size: contain;
border-radius:10px 10px;
margin-top:25px;
padding:25px;
p{text-align:center;}
    margin-left:10px;
`
const Wrap=styled.div`
img{width:100px;
  padding-left:8px;
  margin-left:25px;
  top:1px;}
padding:10px 10px;
align-items:center;
display:flex;
justify-content:center;
justify-items:center;
&:hover{cursor:pointer;}


&:hover{border:2px white solid;border-radius:10px 10px;};

img{max-width:100%;max-height:100%;border-radius:10px 10px;}
`
const Down=styled.div`
padding:10px 10px;
text-align:center;

`
const Logo=styled.img`
width:70px;
padding-left:8px;
margin-left:25px;
top:1px;
`
const Headertop=styled.div`
display:flex;
flex-direction:row;
align-items:center;
padding:10px 0px;
span{
    margin-left:10px;
    font-family:'Times New Roman', Times, serif;
font-weight: 500;
font-size:25px;
}
`