import React from 'react';
import styled from 'styled-components';

function Security() {
  return <Container>
  <Top><Left><h1>Now You are Safe</h1>
  <p className='details'>
<button>Learn More</button>



 <br/><br/>
  </p>
  
  </Left><Right><img src="/images/security_re_a2rk.svg"/></Right></Top>
  <Toptwo><Lefttwo><h1>ChatSpeedy Security</h1>
  <p className='details'>
  From day one, we built ChatSpeedy to help you stay in touch with friends, share vital information.Some of your most personal moments are shared with ChatSpeedy, which is why we built end-to-end encryption into our app. When end-to-end encrypted, your messages, photos, voice messages and documents are secured from falling into the wrong hands.
  For Calls we use JistiMeet SDK which is support end-to-end encryption,For More Details Please Visit <a href='https://jitsi.org/e2ee-in-jitsi/'>Here</a>
  <center>
  <Footer>
    <CWrapper>
      <Dyamamic>
        <li><span>hello</span></li>
        <li><span style={{color:"blue"}}>K¸xÛ |aXGD</span></li>
      </Dyamamic>
    </CWrapper>
    <h4>Security by Default</h4>
    </Footer>
</center>


 <br/><br/>
  </p>
  
  </Lefttwo></Toptwo>
  <h3>You Can Control it </h3>
  <p>    with ChatSpeedy you can block any unwanted user from your messaging list, <br/>this features keep you safe,secure and fresh.</p>
  <Topthree>
     
      
      
      <Leftthree><img src='/images/phone_call_re_hx6a.svg'/>
  <p className='details'>
  <h4>Calls are Safe</h4>
  For Calls we use JistiMeet SDK which is support end-to-end encryption,<br/>For More Details Please Visit <a href='https://jitsi.org/e2ee-in-jitsi/'>Here</a>

  </p>
  
  </Leftthree><Rightthree><img src="/images/safe.svg"/>  <p className='details'>
      <h4>Messages that stay with you and receiver</h4>
  End-to-end encrypted messages are stored on ChatSpeedy Servers after they are delivered.



<br/><br/>
 </p></Rightthree></Topthree>
  
  
  </Container>;
}

export default Security;
const CWrapper=styled.div`
margin-top:30px;
display:inline-flex;
max-width:100%;

width:fit-content;
padding:50px 50px;
position:relative;

`
const Dyamamic=styled.div`
overflow:hidden;
height:35px;
line-height:35px;
li{color:Black;list-style:none;
 animation:slide 4s steps(2) infinite;
 top:0;
 position:relative;
 font-size:25px;font-weight:500;span::after{
 content:"";
 position:absolute;
 left:0;
 background: aliceblue;
 height:100%;
 width:100%;
 border-left:2px black solid;
 animation:typing 3s steps(15) infinite;
}}

li span{
 position:relative;
}
`
const Footer=styled.div`
border-radius:5px 5px;

padding:10px 10px;


`
const  Toptwo=styled.div`
display:flex;
flex-direction:column;
padding:10px 10px;
margin-top:15px;
background-color:aliceblue;
@media (max-width:480px){
  flex-direction:column;
}

`
const  Topthree=styled.div`
display:flex;
padding:10px 10px;
justify-content: center;
align-items: center;
flex-direction:row;
padding:10px 10px;
margin-top:15px;
background-color:aliceblue;
@media (max-width:480px){
  flex-direction:column;
}

`
const Leftthree=styled.div`
width:330px;
margin:0px 30px 0px 30px;
img{
width:100%;
}


`
const Rightthree=styled.div`
width:330px;
margin:0px 30px 0px 30px;
img{
width:100%;
}

`
const Lefttwo=styled.div`
h1{color:black;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-weight:500;font-size:30px;color:blue;}
`
const Top=styled.div`
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
padding:10px 10px;
margin-top:15px;
background-color: rgb(228, 228, 228);
@media (max-width:480px){
  flex-direction:column;
}

`
const Left=styled.div`
h1{color:black;}
`
const Right=styled.div`
width:330px;

img{
width:100%;
}

`
 const Container=styled.main`
 padding:0 calc(3.5vw + 15px);
padding-top:10px;


padding-bottom:40px;

 ::before{    
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    }

 `