import React,{useState,useEffect} from 'react';
import styled from 'styled-components';


import {useNavigate,Link} from'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';

function Header() {


var [sideopen,setsideopen]=useState(false);
const changeState=()=>{
    if(sideopen){
    setsideopen(false);}
    else{
        setsideopen(true);
    }
}




  return <Nav>
  
   <Logo src='/images/chatspeedy.png'/><span className='app-name'>ChatSpeedy</span>
   <div className='custom'>
      <CustomMenu onClick={changeState}/></div>
<SideMenu show={sideopen}>
<CloseIcon onClick={changeState}/>
<li><Link to="/"><span>HOME</span></Link></li>
    <li><Link to="/features" onClick={()=>{setsideopen(false);}} ><span>FEATURES</span></Link></li>
    <li><Link to="/security" onClick={()=>{setsideopen(false);}}><span>SECURITY</span></Link></li>
    <li><Link to="/download" onClick={()=>{setsideopen(false);}}><span>DOWNLOAD</span></Link></li>
    <li><Link to="/savetree" onClick={()=>{setsideopen(false);}}><span>SAVE TREE</span></Link></li>
    <li><Link to="/faq" onClick={()=>{setsideopen(false);}}><span>HELP CENTER</span></Link></li>
    <li><Link to="/privacy" onClick={()=>{setsideopen(false);}}><span>PRIVACY POLICIES</span></Link></li>
    
</SideMenu>
   <NavMenu>
    
    <Link to="/" ><span>HOME</span></Link>
    <Link to="/features" ><span>FEATURES</span></Link>
    
    <Link to="/security" ><span>SECURITY</span></Link>
    <Link to="/download" ><span>DOWNLOAD</span></Link>
    <Link to="/savetree" ><span>SAVE TREE</span></Link>
    <Link to="/faq" ><span>HELP CENTER</span></Link>
    <Link to="/privacy" ><span>PRIVACY POLICIES</span></Link>
   
    
    
    </NavMenu>
    
    
     </Nav>
}

export default Header;

const Nav=styled.div`
background-color: rgb(228, 228, 228);
max-width:100%;

padding:10px 10px;

`
const Logo=styled.img`
width:50px;
padding-left:8px;
margin-left:25px;
top:1px;
`
const NavMenu=styled.div`
position:absolute;
top:18px;
right:90px;
align-items:center;
max-width:100%;
margin:10px 10px;
display:flex;
@media (max-width:990px){
    display:none; 
}
a{display:flex;align-items:center;padding:0 12px;color:black;
  
text-decoration:none;
img{width:20px;}
span{font-size:18px;position:relative;font-family: "Lucida Console", "Courier New", monospace;}
span::after{content:"";background:white;height:2px;position:absolute;bottom:-10px;left:0px;right:0px;transform:scaleX(0)}
}
a:hover{
  border:2px white solid;
  background-color:rgb(0,170,0,0.3);
  padding:5px 8px 5px 8px;
  border-radius: 5px 5px;
    span::after{transform:scaleX(1);
    
        transition:all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;
    
    }   
}
`
const CustomMenu=styled(MenuIcon)`
cursor:pointer;
position:absolute;
margin-right:20px;
font-size:50px;
top:20px;

`
const CloseIcon=styled(Close)`
cursor:pointer;
position:fixed;
right:2px;
padding:5px 5px;
background-color:white;
cursor:pointer;
&:hover{background-color:red;color:white;}
margin-bottom:10px;
`;
const SideMenu=styled.div`

li{list-style:none;padding:25px 10px;font-weight:400;border-bottom:2px solid black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-bottom:1px solid rgba(0, 0, 0, 0.637);}
z-index:16;
background:white;
width:200px;
position:fixed;
right:0;
top:0;
height:70vh;
border-bottom-left-radius: 20px;
@media (min-width:990px){
    display:none;
}
transform:${props=>props.show? 'translateX(0)':'translateX(100%)'};
`