 import React from 'react';
 import styled from 'styled-components';
 import { Link } from 'react-router-dom';
 import Bounce from 'react-reveal/Bounce';
 function Home() {
   return <Container>
     <Top><Left><h1>Simple, Faster and Safe</h1>
     <p className='details'>

    with ChatSpeedy you'll get fast, simple, secure messaging and calling for free*, <br/>available on phones all over the world.
    <br/><br/><p className='data-charge'>* Data charges may apply. Contact your provider for details.</p>
     </p>
     
     </Left><Right><img src="/images/chat activity.jpg"/></Right></Top>
<center>
  <Footer>
  <h2>All of Your Chat Messages End-to-end Encrypted</h2>
    <CWrapper>
      <Dyamamic>
        <li><span>hello</span></li>
        <li><span style={{color:"blue"}}>K¸xÛ |aD</span></li>
      </Dyamamic>
    </CWrapper>
    <h4>Security by Default</h4>
    <p>Some of your most personal moments are shared on ChatSpeedy, which is why we built end-to-end encryption of our app. When end-to-end encrypted, your messages are secured so only you and the person you're communicating with can read or listen to them, and nobody in between.
      
      For Calls we use JistiMeet SDK which is support end-to-end encryption,For More Details Please Visit <a href='https://jitsi.org/e2ee-in-jitsi/'>Here</a>
      
      
      
       </p></Footer>
</center>
<Bounce bottom>
<Features><Link to="/features"><button>more Features</button></Link></Features></Bounce>
   </Container>;
 }
 
 export default Home;

const Top=styled.div`
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
@media (max-width:480px){
  flex-direction:column;
}

`
const Left=styled.div`

`
const Right=styled.div`


img{
  width:330px;
}

`
 const Container=styled.main`
 padding:0 calc(3.5vw + 15px);
padding-top:10px;


padding-bottom:40px;

 ::before{    
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    }

 `
 const CWrapper=styled.div`
 margin-top:30px;
 display:inline-flex;
max-width:100%;

width:fit-content;
padding:50px 50px;
position:relative;
 
 `
 const Dyamamic=styled.div`
 overflow:hidden;
 height:35px;
 line-height:35px;
 li{color:Black;list-style:none;
  animation:slide 4s steps(2) infinite;
  top:0;
  position:relative;
  font-size:25px;font-weight:500;span::after{
  content:"";
  position:absolute;
  left:0;
  background: linear-gradient(90deg,white,lightgreen);
  height:100%;
  width:100%;
  border-left:2px black solid;
  animation:typing 3s steps(15) infinite;
}}
 
 li span{
  position:relative;
 }
 `
 const Footer=styled.div`
 background: linear-gradient(90deg,white,lightgreen);
border-radius:5px 5px;
 
padding:10px 10px;


`
const Features=styled.div`
display:flex;
justify-content: center;
align-items: center;
margin-top:25px;
button:hover{
  transform: scaleX(1.1);
  cursor:pointer;
}
button{animation:animate infinite 1.5s;color:black;padding:20px 20px;font-size:20px;
  background: linear-gradient(110deg,white,lightblue);

}
`