import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import Header from './Components/Header';
import {Routes,Route,Link,BrowserRouter} from 'react-router-dom';
import Download from './Components/Download';
import Privacy_ChatSpeedy from './Components/Privacy_ChatSpeedy';
import Features from './Components/Features';
import Security from './Components/Security';
import HelpCenter from './Components/HelpCenter';
import SaveTree from './Components/SaveTree';
function App() {
  return (
    <div className="App">
<BrowserRouter basename='/'>
     <Header/>
<Routes>
  <Route exact path='/download' element={<Download/>}/>
  <Route exact path='/' element={<Home/>}/>
  <Route exact path='/savetree' element={<SaveTree/>}/>
  <Route exact path='/features' element={<Features/>}/>
  <Route exact path='/security' element={<Security/>}/>
  <Route exact path="/privacy" element={<Privacy_ChatSpeedy/>}/>
  <Route exact path="/faq" element={<HelpCenter/>}/>
  
</Routes>

     </BrowserRouter>
    </div>
  );
}

export default App;
