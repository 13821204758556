import { Link } from "react-router-dom";
import styled from "styled-components";
function SaveTree(){

return <Container>
    <a href='#2'><Arrow src='/images/ic_launcher-save-tree-playstore.png' /></a>
     <Top><Left><h1>Save Tree</h1>
     <p className='details'>

    Every Day the rate of Growing tree is decresing,Save Tree application helps you when your favorite tree needs water.

    <br/><br/><p className='data-charge'><a href="https://play.google.com/store/apps/details?id=com.chatspeedy.savetree" target={"_blank"}><button>Download Now</button></a></p>
     </p>
     
     </Left><Right><img src="/images/image2_1080x1920.jpeg"/></Right></Top></Container>}
     export default SaveTree;
     const Arrow=styled.img`
height:40px;
margin-top:53px;
background-color:black;
width:40px;
animation:animate infinite 1.5s;
cursor:pointer;
`
const Top=styled.div`
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
padding:10px 10px;
margin-top:15px;
@media (max-width:480px){
  flex-direction:column;
}

`
const Left=styled.div`

`
const Right=styled.div`


img{
  width:330px;
}

`
 const Container=styled.main`
 padding:0 calc(3.5vw + 15px);
padding-top:10px;
background-color:rgb(189,221,242);

padding-bottom:40px;

 ::before{    
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    }
button{cursor:pointer;}
 `

